import "./App.css";
import Navigations from "./Navigations";
import keycloakAuthClient from "./keycloak";
import Cookies from "universal-cookie";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { useState } from "react";
import { CircularProgress, Backdrop } from "@mui/material";

function App() {
  const [keycloakInitialized, setKeycloakInitialized] = useState(false); // State to track Keycloak initialization
  const cookies = new Cookies();

  const handleOnToken = (token) => {
    cookies.set("accessToken", token.token);
    cookies.set("refreshToken", token.refreshToken);
    setKeycloakInitialized(true); // Set state to true once tokens are set
  };

  const eventLogger = (event, error) => {
    console.log("onKeycloakEvent", event, error);
    if (event === "onAuthSuccess" || event === "onReady") {
      setKeycloakInitialized(true); // Set state when Keycloak is ready
    }
    if (event === "onAuthLogout") {
      setKeycloakInitialized(false); // Reset state on logout
    }
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloakAuthClient}
      onEvent={eventLogger}
      onTokens={handleOnToken}
      autoRefreshToken={true}
      initOptions={{
        onLoad: "login-required",
        checkLoginIframe: false,
      }}
    >
      {keycloakInitialized ? (
        <Navigations />
      ) : (
        // Show loading indicator until Keycloak is initialized
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="primary" />
        </Backdrop>
      )}
    </ReactKeycloakProvider>
  );
}

export default App;
