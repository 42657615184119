import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import style from "./style.module.css";
import logo from "../../Assets/Images/logo.png";
import Cookie from "universal-cookie";
import MeetingRoomRoundedIcon from "@mui/icons-material/MeetingRoomRounded";
import SupervisedUserCircleRoundedIcon from "@mui/icons-material/SupervisedUserCircleRounded";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import IconButton from "@mui/material/IconButton";

const Drawer = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookie();

  const [feature, setFeature] = useState("Manage");
  const [subFeature, setSubFeature] = useState("Clients");

  let subFeatureList;
  if (feature === "Manage") {
    subFeatureList = [{ title: "Clients" }];
  } else {
    subFeatureList = [];
  }
  return (
    <div className={style.drawerContainer}>
      <div
        style={{
          display: "flex",
          flex: 1,
          height: "100vh",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div className={style.logoContainer}>
            <div>
              <IconButton
                onClick={() => {
                  window.location.href = process.env.REACT_APP_INTRA_URL;
                }}
              >
                <DashboardRoundedIcon
                  style={{
                    color: "white",
                    paddingTop: 8,
                    paddingLeft: 12,
                    paddingRight: 5,
                  }}
                />
              </IconButton>
            </div>
            <img src={logo} style={{ width: 130 }} />
          </div>
          <div>
            <div className={style.section}>Manage</div>
            <DashboardButton
              data={subFeatureList}
              onValueChange={(id, title) => {
                // console.log("SubFeature: ", id, title);
                navigate("/");
                console.log(location.pathname);
                props.onChange(title);
                setSubFeature(title);
              }}
            />
          </div>
        </div>
        <div
          className={style.logout}
          onClick={() => {

            cookies.remove("accessToken");
            cookies.remove("*");
            cookies.set("isSignedOut", true);
          }}
        >
          <div>
            <MeetingRoomRoundedIcon />
          </div>
          <div style={{ marginLeft: 10 }}>Logout</div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;

const DashboardButton = ({ data, onValueChange }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <div>
      {data.map((x, i) => (
        <DashboardButtonHelper
          id={i}
          key={i}
          title={x.title}
          icon={x.icon}
          selectedIndex={selectedIndex}
          callback={(id, title) => {
            setSelectedIndex(id);
            if (onValueChange) {
              onValueChange(id, title);
            }
          }}
        />
      ))}
    </div>
  );
};

const DashboardButtonHelper = ({
  callback,
  title,
  icon,
  id,
  selectedIndex,
}) => {
  const clicked = selectedIndex === id;

  return (
    <div
      onClick={() => {
        callback(id, title);
      }}
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "row",
        backgroundColor: clicked ? "#2E789B" : "#0f3c50",
        color: clicked ? "#00D000" : "#828282",
        padding: "13px 0",
        paddingLeft: 20,
      }}
    >
      <div style={{ paddingTop: 4 }}>
        <SupervisedUserCircleRoundedIcon />
      </div>
      <div className={style.textBtn}>{title}</div>
    </div>
  );
};
